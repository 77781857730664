// themecolor
$themecolor: #4852d6;

$primarybtn: #4852d6;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #3f46a3;

$gradient-two: #282c6e;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
