@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.wallet {
    .morphisam {
        border-radius: 46px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;
        padding: 30px;
        position: relative;

        @media #{$small-device} {
            padding: 15px;
        }

        &::after {
            position: absolute;
            content: "";
            width: 95%;
            height: 30px;
            bottom: -5px;
            z-index: -1;
            background-color: $mate-black;
            border-radius: 30px;
            left: 0;
            right: 0;
            margin: auto;

            @media #{$small-device} {
                width: 80%;
            }
        }

        .image {
            img {
                width: 100%;
            }
        }

        .request_data {

            input[type="number"]{
                &::before{
                    content: "$";
                    display: block;
                }
            }

            .inner_symbol{
                position: relative;

                .currency_symbol{
                    position: absolute;
                    top: 8px;
                    left: 10px;
                }
            }
            .price {
                color: $themecolor;
                font-weight: 600;
                outline: none;
                border: 1px solid #e5e4e4;
                padding-left: 22px;
                border-radius: 5px;
                height: 40px;
            }

            .total_coin {
                color: $themecolor;
                font-weight: 600;

                img{
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    max-width: 100%;
                }
            }
            .notes_data {
                color: $themecolor;
            }
        }

        .total_earnings {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                padding: 15px 25px;
                border-radius: 10px;
                background-color: #e7e7e7;
                color: $themecolor;
            }
        }

        .reedem_request {
            background-color: #e7e7e7;
            padding: 20px;
            border-radius: 10px;
            margin-top: 10px;
            .redeem,
            .payment_adderess {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .redeem_price {
                background-color: $themecolor;
                color: #fff;
                padding: 5px;
                border-radius: 10px;
            }
            p {
                color: $themecolor;
            }

            .payment_status,
            .payment_type {
                margin-bottom: 0;
            }
        }

        .nav-fill {
            .nav-item {
                .nav-link {
                    color: #000;
                    font-weight: 400;
                    letter-spacing: 0.5px;
                }
            }
        }

        .nav-tabs {
            .nav-link.active {
                color: $themecolor;
                font-weight: 600;
                letter-spacing: 0.5px;
            }
        }

        .error_img {
            height: 80px;
        }
    }
}

.input_data {
    input {
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        padding-left: 20px;
        height: 40px;
        border-radius: 5px;
        border: none;
        background: #e1e5e8;
        transition: color 0.3s ease-out;
        outline: none;
        letter-spacing: 1px;
        color: #000;

        &::placeholder {
            text-align: center;
            text-transform: capitalize;
        }
    }
}

.payment_icon {
    display: flex;
    align-items: center;
    li {
        list-style-type: none;
        margin-right: 20px;
        background-color: $themecolor;
        border-radius: 5px;
        padding: 0px 25px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
            font-size: 24px;
            margin-top: -5px;
        }
    }
}
